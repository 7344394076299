<template>
  <div class="sso">
    <ProConnectButton />
  </div>
</template>

<style scoped>
  .sso {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
